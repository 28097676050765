import { Phone } from '.';

export class Customer {
  id?: string;
  created_at?: number;
  deleted_at?: number;
  updated_at?: number;

  name: string;
  is_deleted: boolean;
  address?: string;
  phone_numbers?: string;

  phones: Phone[];

  constructor() {
    this.phones = [];
  }
}

export interface CustomerAnalysis {
  totalNumberOfCustomers: number;
}
