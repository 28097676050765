import { APP_BASE_HREF, registerLocaleData } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LOCALE_ID, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import localeTr from '@angular/common/locales/tr';
import localeTrExtra from '@angular/common/locales/extra/tr';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreModule } from './@core/core.module';
import { ThemeModule } from './@theme/theme.module';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

// Firebase
import { AngularFireModule } from 'angularfire2';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { AngularFireStorageModule } from 'angularfire2/storage';

// Auth
import { AuthGuard } from './auth/auth-guard.service';
import { AuthService } from './auth/auth-service.service';
import { LoginComponent } from './auth/login/login.component';

// Data
import { CustomerService } from './data/customer-service.service';
import { DataService } from './data/data-service.service';
import { DefinitionService } from './data/definition-service.service';
import { PlugService } from './data/plug-service.service';
import { SettingsService } from './data/settings-service.service';

export const firebaseConfig = {
  apiKey: "AIzaSyD_79jvJNntPcQHfwXxXlSWmFiXA6iPrBE",
  authDomain: "bayraktaryazilim.firebaseapp.com",
  databaseURL: "https://bayraktaryazilim.firebaseio.com",
  projectId: "bayraktaryazilim",
  storageBucket: "bayraktaryazilim.appspot.com",
  messagingSenderId: "887622226556"
};

registerLocaleData(localeTr, 'tr', localeTrExtra);

@NgModule({
  declarations: [AppComponent, LoginComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,

    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFireStorageModule,

    NgbModule.forRoot(),
    ThemeModule.forRoot(),
    CoreModule.forRoot(),
  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: LOCALE_ID, useValue: 'tr' },
    AuthGuard,
    AuthService,
    CustomerService,
    DataService,
    DefinitionService,
    PlugService,
    SettingsService,
  ],
})
export class AppModule {
}
