import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';

import { NB_AUTH_OPTIONS, NbAuthSocialLink } from '@nebular/auth';
import { getDeepFromObject } from '@nebular/auth/helpers';

import { AuthService } from '../auth-service.service';
import { DataService } from '../../data/data-service.service';

import { Navigations } from '../../app.global';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  styleUrls: ['./login.component.scss'],
  templateUrl: './login.component.html',
})
export class LoginComponent {

  redirectDelay: number = 0;

  errors: string[] = [];
  messages: string[] = [];
  user: any = { rememberMe: true };

  showMessages: any = {};
  submitted: boolean = false;
  socialLinks: NbAuthSocialLink[] = [];

  validation = {};

  constructor(
    protected auth: AuthService,
    protected data: DataService,
    @Inject(NB_AUTH_OPTIONS) protected config = {},
    protected router: Router) {
    this.redirectDelay = this.getConfigValue('forms.login.redirectDelay');
    this.showMessages = this.getConfigValue('forms.login.showMessages');
    this.socialLinks = this.getConfigValue('forms.login.socialLinks');

    this.validation = this.getConfigValue('forms.validation');
    this.auth.userFetched.pipe(first()).subscribe(user => {
      if (user) {
        console.log(user);
        this.router.navigate([Navigations.dashboard]);
      }
    });
  }

  loginEmail() {
    this.errors = this.messages = [];
    this.submitted = true;

    this.auth.signInWithEmail(this.user.email, this.user.password)
      .then((res) => {
        this.submitted = false;
        this.messages = [res.operationType];
      }).catch(this.catchError);
  }

  loginSocial(name) {
    if (name === "google") {
      this.loginGoogle();
    } else if (name === "facebook") {
      this.loginFb();
    } else{
      console.warn("No login for " + name);
    }
  }

  loginGoogle() {
    this.auth.signInWithGoogle()
      .then((success) => {
        this.redirectToDashboard()
      }).catch(this.catchError);
  }

  loginFb() {
    this.auth.signInWithFacebook()
      .then((success) => {
        this.redirectToDashboard()
      }).catch(this.catchError);
  }

  catchError(err) {
    this.submitted = false;
    this.errors = [err];
  }

  redirectToDashboard(){
    setTimeout(() => {
      this.router.navigate([Navigations.dashboard]);
    }, this.redirectDelay);
  }

  getConfigValue(key: string): any {
    return getDeepFromObject(this.config, key, null);
  }
}
