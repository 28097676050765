import { EventEmitter, Injectable, Output } from '@angular/core';
import { Router } from "@angular/router";

import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase';

import { Observable } from 'rxjs/Observable';

import { User } from '../models';
import { Navigations } from '../app.global';

@Injectable()
export class AuthService {

  @Output() loggedIn: EventEmitter<firebase.User> = new EventEmitter();
  @Output() userFetched: EventEmitter<User> = new EventEmitter();

  public firebaseUser: Observable<firebase.User>;
  public firebaseUserDetails: firebase.User = null;
  public user: User;

  constructor(private router: Router, private _firebaseAuth: AngularFireAuth) {
    this.firebaseUser = _firebaseAuth.authState;
    this.firebaseUser.subscribe((user) => {
      if (user) {
        this.firebaseUserDetails = user;
        console.log(this.firebaseUserDetails);
        this.loggedIn.emit(user);
      } else {
        this.firebaseUserDetails = null;
        this.loggedIn.emit(null);
      }
    }
    );
  }

  requestPass(email) {
    return this._firebaseAuth.auth.sendPasswordResetEmail(email);
  }

  confirmPasswordReset(code, newPassword) { // param: oobCode=<code>
    return this._firebaseAuth.auth.confirmPasswordReset(code, newPassword);
  }

  verifyPasswordResetCode(code){
    return this._firebaseAuth.auth.verifyPasswordResetCode(code);
  }

  signInWithEmail(email, password) {
    return this._firebaseAuth.auth.signInWithEmailAndPassword(email, password);
  }

  signInWithTwitter() {
    return this._firebaseAuth.auth.signInWithPopup(
      new firebase.auth.TwitterAuthProvider()
    )
  }

  signInWithFacebook() {
    return this._firebaseAuth.auth.signInWithPopup(
      new firebase.auth.FacebookAuthProvider()
    )
  }

  signInWithGoogle() {
    return this._firebaseAuth.auth.signInWithPopup(
      new firebase.auth.GoogleAuthProvider()
    )
  }

  isLoggedIn() {
    if (this.firebaseUserDetails == null) {
      return false;
    } else {
      return true;
    }
  }

  logout() {
    this._firebaseAuth.auth.signOut()
      .then((res) => {
        this.loggedIn.emit(null);
        this.router.navigate([Navigations.login])
      });
  }
}
