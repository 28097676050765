import { Injectable, Output, EventEmitter } from '@angular/core';

import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';

import { map, take } from 'rxjs/operators';

import { Definition, DefinitionAnalysis } from '../models';
import { Database, Global } from '../app.global';

@Injectable()
export class DefinitionService {

  @Output() definitionsFetched: EventEmitter<Definition[]> = new EventEmitter();

  public analytics: DefinitionAnalysis;
  public definitions: Definition[] = [];
  public definitionsRef: AngularFireList<any>;

  constructor(private db: AngularFireDatabase) {
    this.analytics = {
      totalNumberOfItemNames: 0,
      totalNumberOfProductNames: 0,
    };
  }

  public init(): Promise<void> {
    this.definitions = [];
    this.definitionsRef = this.db.list(`${Database.definitions}/${Global.tenancyName}`);
    return new Promise<void>((resolve, reject) => this.definitionsRef.snapshotChanges()
      .pipe(
        take(1),
        map(changes => changes.map(c => ({ id: c.key, ...c.payload.val()})))
      ).subscribe(definitions => {
        console.log('definition service initialized.');
        this.definitions.push(...definitions.filter(d => !d.is_deleted));
        this.definitions.sort((a, b) => b.created_at - a.created_at);
        this.analyze();
        this.definitionsFetched.emit(this.definitions);
        resolve();
      }, err => reject(err)));
  }

  public add(definition: Definition): Promise<void> {
    const selfDefinition = definition;
    selfDefinition.created_at = new Date().getTime();

    return this.definitionsRef.push({
      created_at: selfDefinition.created_at,
      name: selfDefinition.name,
      is_deleted: false,
      type: selfDefinition.type,
    }).then(res => {
      selfDefinition.id = res.key;
      this.definitions.unshift(selfDefinition);
      this.analyze();
    }, this.handleError);
  }

  public delete(definition: Definition): Promise<void> {
    const selfDefinition = definition;
    if (!selfDefinition.id) {
      throw new Error(`This item's id has not been set: ${selfDefinition.name}`);
    }

    const findIndex = this.definitions.findIndex(c => c.id === selfDefinition.id);
    if (findIndex === -1) {
      throw new Error(`This item is not in the list: ${findIndex}`);
    }

    return this.definitionsRef.update(selfDefinition.id, {
      deleted_at: new Date().getTime(),
      is_deleted: true,
    }).then(res => {
      this.definitions.splice(findIndex, 1);
      this.analyze();
    }, this.handleError);
  }
  
  public update(definition: Definition): Promise<void> {
    const selfDefinition = definition;
    selfDefinition.updated_at = new Date().getTime();

    return this.definitionsRef.update(selfDefinition.id, {
      name: selfDefinition.name,
      updated_at: selfDefinition.updated_at,
    }).then(res => {
      const findIndex = this.definitions.findIndex(c => c.id === selfDefinition.id);
      this.definitions[findIndex].name = selfDefinition.name;
      this.definitions[findIndex].updated_at = selfDefinition.updated_at;
      this.analyze();
    }, this.handleError);
  }

  public analyze(): void {
    this.analytics.totalNumberOfItemNames = this.definitions.filter(d => d.type == '1').length;
    this.analytics.totalNumberOfProductNames = this.definitions.filter(d => d.type == '2').length;
  }

  private handleError(err) {
    console.error(err);
  }
}