export class User {
  id?: string;
  created_at?: number;

  is_admin: boolean;
  display_name: string;
  email: string;
  tenancy_name: string;
  photo_url: string;
  phone_number: string;
}