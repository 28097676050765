import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { map, tap, take } from 'rxjs/operators';
import { AuthService } from './auth-service.service';
import { Navigations } from '../app.global';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private auth: AuthService, private router: Router) { }

  canActivate() {
    return this.auth.firebaseUser.pipe(
      take(1),
      map(authState => !!authState),
      tap(authenticated => {
        if (!authenticated) {
          this.router.navigate([Navigations.login]);
        }
      })
    );
  }
}
