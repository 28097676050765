import { EventEmitter, Injectable, Output } from '@angular/core';

import { AngularFireDatabase } from 'angularfire2/database';

import { map, take, takeWhile } from 'rxjs/operators';

import { Settings } from '../models';
import { Database, Global } from '../app.global';

@Injectable()
export class SettingsService {

  @Output() settingsChanged: EventEmitter<Settings> = new EventEmitter();

  public settings: Settings = new Settings();
  public watchChanges: boolean = false;

  constructor(private db: AngularFireDatabase) {
    this.settings.app_version = Global.appVersion;
  }

  public init() {
    this.watchChanges = true;
    this.db.object<Settings>(`${Database.settings}`)
      .valueChanges()
      .pipe(takeWhile(() => this.watchChanges))
      .subscribe((settings: Settings) => {
        this.settings.app_version = settings.app_version;
        this.settingsChanged.emit(settings);
      });
  }
}
