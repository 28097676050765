import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { take } from 'rxjs/operators';

import { AnalyticsService } from './@core/utils/analytics.service';
import { AuthService } from './auth/auth-service.service';
import { CustomerService } from './data/customer-service.service';
import { DataService } from './data/data-service.service';
import { DefinitionService } from './data/definition-service.service';
import { PlugService } from './data/plug-service.service';
import { SettingsService } from './data/settings-service.service';
import { User, Settings } from './models';
import { Database, Global, Navigations } from './app.global';

@Component({
  selector: 'ngx-app',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {

  constructor(
    private router: Router,
    private analytics: AnalyticsService,
    private auth: AuthService,
    private customer: CustomerService,
    private data: DataService,
    private definition: DefinitionService,
    private plug: PlugService,
    private settings: SettingsService) {

    this.auth.loggedIn.subscribe((firebaseUser: firebase.User) => {
      if (firebaseUser) {
        this.data.getSnapshot<User>(`${Database.users}/${firebaseUser.uid}`)
          .pipe(take(1))
          .subscribe(user => {
            console.log(user);
            this.auth.user = user;
            Global.tenancyName = user.tenancy_name;
            this.auth.userFetched.emit(user);
            this.getAllData();
          }, err => console.error(err));
      } else {
        this.settings.watchChanges = false;
        this.router.navigate([Navigations.login]);
      }
    });

    let settingsInterval: any;
    this.settings.settingsChanged.subscribe((settings: Settings) => {
      console.log(`Settings changed: ${settings.app_version}`);

      if (settings.app_version !== Global.appVersion) {
        if (settingsInterval) {
          clearInterval(settingsInterval);
        }

        settingsInterval = setInterval(() => window.confirm('Uygulamanın yeni bir sürümü yayınlandı, Lütfen yarıda kalan çalışmanızı kayıt edip sayfayı yenileyiniz.'), 5 * 60 * 1000);
      }
    });
  }

  ngOnInit(): void {
    this.analytics.trackPageViews();
  }

  private async getAllData() {
    // Get all data and cache them
    this.settings.init();
    await this.definition.init();
    await this.customer.init();
    await this.plug.init(this.customer.customers);
  }
}
