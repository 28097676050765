export const Global = {
  appVersion: "1.00",
  language: 'tr-TR',
  tenancyName: 'default'
};

export const Database = {
  customers: 'bakimtakip/customers',
  definitions: 'bakimtakip/definitions',
  plugs: 'bakimtakip/plugs',
  settings: 'bakimtakip/settings',
  users: 'bakimtakip/users',
};

export const Navigations = {
  customers: 'pages/maintenance/customers',
  dashboard: 'pages/maintenance/dashboard',
  items: 'pages/maintenance/definitions?type=1',
  login: 'auth/login',
  plugs: 'pages/maintenance/plugs',
  products: 'pages/maintenance/definitions?type=2',
};
