import { Line } from '.';

export enum PlugStatusEnum {
  _1 = 1,
  _2 = 2,
  _3 = 3,
  _4 = 4,
  _5 = 5,
  _6 = 6,
  _7 = 7,
  _8 = 8,
  _9 = 255
}

export class PlugStatus {
  static MaintenanceCreated: number = PlugStatusEnum._1;
  static DontAskForACall: number = PlugStatusEnum._2;
  static NoOneAnswered: number = PlugStatusEnum._3;
  static MustAskForACall: number = PlugStatusEnum._4;
  static MaintenanceHasBeenDone: number = PlugStatusEnum._5;
  static PhoneNumberIsNoLongerUsed: number = PlugStatusEnum._6;
  static CustomerWillCallYou: number = PlugStatusEnum._7;
  static SomeoneOnThePhone: number = PlugStatusEnum._8;
  static Other: number = PlugStatusEnum._9;
}

export class Plug {
  id?: string;
  customer_id?: string;
  created_at?: number;
  deleted_at?: number;
  updated_at?: number;

  is_deleted: boolean;
  started_at: number;
  product_name: string;
  periods: number; // in days.
  status: string;
  lines?: string;
  descriptions?: string;

  customer_name?: string;
  line_array: Line[];
  remainedDays?: number;
  //descrtiption_array: string[];

  constructor() {
    this.line_array = [];
    //this.descrtiption_array = [];
  }
}

export interface PlugAnalysis {
  totalNumberOfPlugs: number;
  totalNumberOfExpired: number;
  totalNumberOf10Days: number;
  totalNumberOf30Days: number;
  totalNumberOf60Days: number;
  totalNumberOf180Days: number;
}
