export enum DefinitionType {
  Item = 1,
  Product
}

export interface Definition {
  id?: string;
  created_at?: number;
  deleted_at?: number;
  updated_at?: number;

  name: string;
  is_deleted: boolean;
  type: string;
}

export interface DefinitionAnalysis {
  totalNumberOfItemNames: number;
  totalNumberOfProductNames: number;
}
