import { Injectable, Output, EventEmitter } from '@angular/core';

import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';

import { map, take } from 'rxjs/operators';

import { Customer, Plug, PlugAnalysis, Line } from '../models';
import { Database, Global } from '../app.global';

@Injectable()
export class PlugService {

  @Output() plugsFetched: EventEmitter<Plug[]> = new EventEmitter();

  public analytics: PlugAnalysis;
  public plugs: Plug[] = [];
  public plugsRef: AngularFireList<any>;

  constructor(private db: AngularFireDatabase) {
    this.analytics = {
      totalNumberOfPlugs: 0,
      totalNumberOfExpired: 0,
      totalNumberOf10Days: 0,
      totalNumberOf30Days: 0,
      totalNumberOf60Days: 0,
      totalNumberOf180Days: 0,
    };
  }

  public init(customers: Customer[]): Promise<void> {
    this.plugs = [];
    this.plugsRef = this.db.list(`${Database.plugs}/${Global.tenancyName}`);

    return new Promise<void>((resolve, reject) => this.plugsRef
      .snapshotChanges()
      .pipe(
        take(1),
        map(changes => changes.map(c => {
          const plug = c.payload.val() as Plug;
          plug.id = c.key;
          //plug.descrtiption_array = JSON.parse(plug.descriptions) as string[];
          plug.line_array = JSON.parse(plug.lines) as Line[];

          return plug;
        }))
      ).subscribe(plugs => {
        console.log('plug service initialized.');
        this.plugs.push(...plugs.filter(c => !c.is_deleted));
        this.plugs.sort((a, b) => b.created_at - a.created_at);
        this.plugs.forEach(p => {
          const customer = customers.find(c => c.id === p.customer_id);
          p.customer_name = customer ? customer.name : '';
        });
        this.analyze();
        this.plugsFetched.emit(this.plugs);
        resolve();
      }, err => reject(err)));
  }

  public addPlug(plug: Plug): Promise<void> {
    const selfPlug = plug;
    selfPlug.created_at = new Date().getTime();
    //selfPlug.descriptions = JSON.stringify(selfPlug.descrtiption_array) || '[]';
    selfPlug.lines = JSON.stringify(selfPlug.line_array) || '[]';

    return this.plugsRef.push({
      customer_id: selfPlug.customer_id,
      created_at: selfPlug.created_at,
      is_deleted: false,
      started_at: selfPlug.started_at,
      product_name: selfPlug.product_name,
      periods: selfPlug.periods,
      status: selfPlug.status,
      //descriptions: selfPlug.descriptions,
      lines: selfPlug.lines,
    }).then(res => {
      selfPlug.id = res.key;
      this.plugs.unshift(selfPlug);
      this.analyze();
    }, this.handleError);
  }

  public async deleteCustomerPlugs(customer: Customer): Promise<void> {
    const plugs = this.plugs.filter(p => p.customer_id === customer.id).slice(0);
    for (let i = 0; i < plugs.length; i++) {
      await this.deletePlug(plugs[i]);
    }
  }

  public deletePlug(plug: Plug): Promise<void> {
    const selfPlug = plug;
    if (!selfPlug.id) {
      throw new Error(`This item's id has not been set: ${selfPlug}`);
    }

    const findIndex = this.plugs.findIndex(c => c.id === selfPlug.id);
    if (findIndex === -1) {
      throw new Error(`This item is not in the list: ${findIndex}`);
    }

    return this.plugsRef.update(selfPlug.id, {
      deleted_at: new Date().getTime(),
      is_deleted: true,
    }).then(res => {
      this.plugs.splice(findIndex, 1);
      this.analyze();
    }, this.handleError);
  }

  public updatePlug(plug: Plug): Promise<void> {
    const selfPlug = plug;
    selfPlug.updated_at = new Date().getTime();
    //selfPlug.descriptions = JSON.stringify(selfPlug.descrtiption_array) || '[]';
    selfPlug.lines = JSON.stringify(selfPlug.line_array) || '[]';

    return this.plugsRef.update(selfPlug.id, {
      updated_at: selfPlug.updated_at,
      periods: selfPlug.periods,
      status: selfPlug.status,
      //descriptions: selfPlug.descriptions,
      lines: selfPlug.lines,
    }).then(res => {
      const findIndex = this.plugs.findIndex(c => c.id === selfPlug.id);
      this.plugs[findIndex].periods = selfPlug.periods;
      this.plugs[findIndex].status = selfPlug.status;
      this.plugs[findIndex].descriptions = selfPlug.descriptions;
      this.plugs[findIndex].lines = selfPlug.lines;
      this.plugs[findIndex].updated_at = selfPlug.updated_at;
      this.analyze();
    }, this.handleError);
  }

  public analyze(): void {
    const now = new Date();

    this.plugs.forEach(plug => {
      let latestTime = plug.started_at;
      plug.line_array = JSON.parse(plug.lines) as Line[];

      if (plug.line_array.length > 0) {
        plug.line_array.sort((a, b) => b.changed_at - a.changed_at);
        latestTime = plug.line_array[0].changed_at;
      }

      plug.remainedDays = plug.periods - Math.round((now.valueOf() - new Date(latestTime).valueOf()) / (1000 * 60 * 60 * 24));
    });

    this.analytics.totalNumberOfPlugs = this.plugs.length;
    this.analytics.totalNumberOfExpired = this.plugs.filter(p => p.remainedDays < 0).length;
    this.analytics.totalNumberOf10Days = this.plugs.filter(p => p.remainedDays <= 10 && p.remainedDays >= 1).length;
    this.analytics.totalNumberOf30Days = this.plugs.filter(p => p.remainedDays <= 30 && p.remainedDays >= 11).length;
    this.analytics.totalNumberOf60Days = this.plugs.filter(p => p.remainedDays <= 60 && p.remainedDays >= 31).length;
    this.analytics.totalNumberOf180Days = this.plugs.filter(p => p.remainedDays <= 180 && p.remainedDays >= 61).length;
  }

  private handleError(err) {
    console.error(err);
  }
}
