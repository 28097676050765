import { Injectable, Output, EventEmitter } from '@angular/core';

import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';

import { map, take } from 'rxjs/operators';

import { Customer, CustomerAnalysis, Phone } from '../models';
import { Database, Global } from '../app.global';

@Injectable()
export class CustomerService {

  @Output() customersFetched: EventEmitter<Customer[]> = new EventEmitter();

  public analytics: CustomerAnalysis;
  public customers: Customer[] = [];
  public customersRef: AngularFireList<any>;

  constructor(private db: AngularFireDatabase) {
    this.analytics = {
      totalNumberOfCustomers: 0,
    };
  }

  public init(): Promise<void> {
    this.customers = [];
    this.customersRef = this.db.list(`${Database.customers}/${Global.tenancyName}`);

    return new Promise<void>((resolve, reject) => this.customersRef
      .snapshotChanges()
      .pipe(
        take(1),
        map(changes => changes.map(c => {
          const customer = c.payload.val() as Customer;
          customer.id = c.key;
          customer.phones = JSON.parse(customer.phone_numbers) as Phone[];

          return customer;
        }))
      ).subscribe(customers => {
        console.log('customer service initialized.');
        this.customers.push(...customers.filter(c => !c.is_deleted));
        this.customers.sort((a, b) => b.created_at - a.created_at);
        this.analyze();
        this.customersFetched.emit(this.customers);
        resolve();
      }, err => reject(err)));
  }

  public addCustomer(customer: Customer): Promise<void> {
    const selfCustomer = customer;
    selfCustomer.created_at = new Date().getTime();
    selfCustomer.phone_numbers = JSON.stringify(selfCustomer.phones) || '[]';

    return this.customersRef.push({
      created_at: selfCustomer.created_at,
      name: selfCustomer.name,
      is_deleted: false,
      address: selfCustomer.address,
      phone_numbers: selfCustomer.phone_numbers,
    }).then(res => {
      selfCustomer.id = res.key;
      this.customers.unshift(selfCustomer);
      this.analyze();
    }, this.handleError);
  }

  public deleteCustomer(customer: Customer): Promise<void> {
    const selfCustomer = customer;
    if (!selfCustomer.id) {
      throw new Error(`This item's id has not been set: ${selfCustomer.name}`);
    }

    const findIndex = this.customers.findIndex(c => c.id === selfCustomer.id);
    if (findIndex === -1) {
      throw new Error(`This item is not in the list: ${findIndex}`);
    }

    return this.customersRef.update(selfCustomer.id, {
      deleted_at: new Date().getTime(),
      is_deleted: true,
    }).then(res => {
      this.customers.splice(findIndex, 1);
      this.analyze();
    }, this.handleError);
  }

  public updateCustomer(customer: Customer): Promise<void> {
    const selfCustomer = customer;
    selfCustomer.updated_at = new Date().getTime();
    selfCustomer.phone_numbers = JSON.stringify(selfCustomer.phones) || '[]';

    return this.customersRef.update(selfCustomer.id, {
      updated_at: selfCustomer.updated_at,
      name: selfCustomer.name,
      address: selfCustomer.address,
      phone_numbers: selfCustomer.phone_numbers,
    }).then(res => {
      const findIndex = this.customers.findIndex(c => c.id === selfCustomer.id);
      this.customers[findIndex].name = selfCustomer.name;
      this.customers[findIndex].address = selfCustomer.address;
      this.customers[findIndex].phone_numbers = selfCustomer.phone_numbers;
      this.customers[findIndex].updated_at = selfCustomer.updated_at;
      this.analyze();
    }, this.handleError);
  }

  public analyze(): void {
    this.analytics.totalNumberOfCustomers = this.customers.length;
  }

  private handleError(err) {
    console.error(err);
  }
}