import { Injectable } from '@angular/core';

import { AngularFireDatabase } from 'angularfire2/database';

import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';

@Injectable()
export class DataService {

  constructor(private db: AngularFireDatabase) {}

  addItem<T>(path: string, item: any) {
    const ref = this.db.list<T>(path);
    return ref.push(item);
  }

  deleteItem<T>(path: string, id: string): Promise<void> {
    const ref = this.db.list<T>(path);
    return ref.remove(id);
  }

  setItem<T>(path: string, item: T): Promise<void> {
    return this.db.object(path).set(item);
  }

  updateItem<T>(path: string, id: string, item: any): Promise<void> {
    const ref = this.db.list<T>(path);
    return ref.update(id, item);
  }

  getSnapshot<T>(path: string): Observable<T> {
    return this.db.object<T>(path).snapshotChanges().pipe(
      map(item => {
        const id = item.key;
        const data = item.payload.val() as T;

        data['id'] = id;

        return data;
      })
    );
  }

  getSnapshots<T>(path: string): Observable<T[]> {
    const ref = this.db.list(path);
    return ref.snapshotChanges().pipe(
      map(items => items
        .map(item => {
          const id = item.key;
          const data = item.payload.val() as T;

          data['id'] = id;

          return data;
      }))
    );
  }

  getValue<T>(path: string): Observable<T> {
    return this.db.object<T>(path).valueChanges();
  }

  getValues<T>(path: string): Observable<T[]> {
    return this.db.list<T>(path).valueChanges();
  }
}