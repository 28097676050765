import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

export class Line {
  public static itemSplit: string = '||';

  created_at?: number;
  changed_at?: number;
  changed_items?: string;
  description?: string;

  changedAt?: NgbDateStruct;
};
